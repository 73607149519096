<template>
  <base-layout title="Payment Successful">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <h1>Payment completed successfully</h1>
          <h4>Please click below if you are not redirected in a 5 seconds.</h4>
        </ion-col>
        <ion-col size="12">
          <ion-buttons>
            <ion-button @click="redirect" class="link"> Redirect </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import { IonGrid, IonRow, IonCol, IonButtons, IonButton } from "@ionic/vue";

export default {
  props: ["invoice"],
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
  },
  data() {
    return {};
  },
  methods: {
    redirect() {
      this.$router.replace({
        name: "invoice.show",
        params: { invoice: this.invoice },
      });
    },
  },

  created() {
    setTimeout(() => this.redirect(), 5000);
  },
};
</script>

<style scoped>
</style>
